.home {
  height: 100vh;
  width: 100vw;
}
.seperator {
  height: 20px;
  width: 100%;
}

*:focus {
  outline: none;
}
@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

button:hover {
  background-color: rgb(130, 67, 67);
  cursor: grab;
}
a:hover {
  cursor: grab;
  color: blueviolet;
}

@media  screen and (max-width: 400px) {
  #hiddenForm {
    padding-top: 10px;
    background-color: #333; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: -200px; /* Hide the navbar 50 px outside of the top view */
    width: 100%; /* Full width */
    transition: top 0.3s;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .hidden-searchbar {
    width: 60%;
    outline: none;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px;
  }
  #hidden-searchbar-input {
    width: 80%;
    font-size: 16px;
    outline: none;
    border: 1px solid #fff;
  }
  #hidden-search-icon {
    height: 14px;
    width: 18px;
    color: #333;
  }
}

@media screen and (min-width:400.1px) and (max-width: 768px) {
  #hiddenForm {
    padding-top: 5px;
    background-color: #333; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: -200px; /* Hide the navbar 50 px outside of the top view */
    width: 100%; /* Full width */
    transition: top 0.3s;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .hidden-searchbar {
    width: 45%;
    outline: none;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px;
  }
  #hidden-searchbar-input {
    width: 80%;
    font-size: 16px;
    outline: none;
    border: 1px solid #fff;
  }
  #hidden-search-icon {
    height: 16px;
    width: 20px;
    color: #333;
  }
}

@media screen and  (min-width:768.1px) and  (max-width: 992px) {
  #hiddenForm {
    padding-top: 5px;
    background-color: #333; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: -200px; /* Hide the navbar 50 px outside of the top view */
    width: 100%; /* Full width */
    transition: top 0.3s;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .hidden-searchbar {
    width: 45%;
    outline: none;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 2%;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #hidden-searchbar-input {
    width: 80%;
    font-size: 18px;
    outline: none;
    border: 1px solid #fff;
  }
  #hidden-search-icon {
    height: 20px;
    width: 20px;
    color: #333;
  }
}

@media screen and (min-width:992.1px) and (max-width: 1400px) {
  #hiddenForm {
    background-color: #333; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: -200px; /* Hide the navbar 50 px outside of the top view */
    width: 100%; /* Full width */
    transition: top 0.3s;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .hidden-searchbar {
    width: 40%;
    outline: none;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #hidden-searchbar-input {
    width: 80%;
    font-size: 18px;
    outline: none;
    border: 1px solid #fff;
  }
  #hidden-search-icon {
    height: 24px;
    width: 24px;
    color: #333;
  }
}

@media screen and (min-width: 1400px) {
  #hiddenForm {
    padding-top: 15px;
    background-color: #333; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: -200px; /* Hide the navbar 50 px outside of the top view */
    width: 100%; /* Full width */
    transition: top 0.3s;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .hidden-searchbar {
    width: 25%;
    outline: none;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5%;
    padding-right: 0.5%;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #hidden-searchbar-input {
    width: 80%;
    font-size: 18px;
    outline: none;
    border: 1px solid #fff;
  }
  #hidden-search-icon {
    height: 26px;
    width: 24px;
    color: #333;
  }
}
