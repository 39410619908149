.contact {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200%;
    width: 100%;
    margin-top: 5px;
    resize: both;
    background-image: url("../home/assets/header-bg-1200.jpg");
}

.section-wrapper {
    display: float;
    flex-direction: column;
    align-items: center;
    background: transparent;
    padding: 20px 25px;
    max-width: 40em;
    height: 600px;
    width: 80%;
    border-radius: 10px;
    margin: 0 auto;
}

.text-wrapper {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}