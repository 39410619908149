.top-part {
  background-color: #8a8280;
}
@media (min-width: 0px) and (max-height: 740px) {
  .top-part {
    background-image: url("../../assets/header-bg-0.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 90%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
  }
  
  .topHeadingPart {
    padding-top: 4%;
    width: 95%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .paraDiv {
    margin-top: 2%;
    width: 90%;
    padding-left: 5px;
    border-left: solid #0dc49a 5px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 9px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
  }
  .searchbar {
    margin-top: 2%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 16px;
    width: 80%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 12%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 16px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 12px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 25px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 100px;
    font-size: 10px;
  }

  .placeholder {
    font-size: 10px;
    color: #25272b;
  }
  .menu {
    font-size: 10px;
  }
}
@media (min-width: 0px) and (min-height: 740.01px) {
  .top-part {
    background-image: url("../../assets/header-bg-0.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 70%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 4%;
    width: 95%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .paraDiv {
    width: 90%;
    padding-left: 8px;
    border-left: solid #0dc49a 5px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 9px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 16px;
    width: 80%;
    outline-width: 0;
  }
  #search-icon {
    width: 20px;
    height: 20px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 10%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 16px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 10px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 25px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 100px;
    font-size: 10px;
  }

  .placeholder {
    font-size: 10px;
    color: #25272b;
  }
  .menu {
    font-size: 10px;
  }
}
@media (min-width: 576px) and (max-height: 600px) {
  .top-part {
    background-image: url("../../assets/header-bg-576.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 300%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 4%;
    width: 70%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 35px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 15px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 10%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 15px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 15px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 15px;
  }

  .placeholder {
    font-size: 15px;
    color: #25272b;
  }
  .menu {
    font-size: 15px;
  }
}
@media (min-width: 576px) and (min-height: 601.01px) {
  .top-part {
    background-image: url("../../assets/header-bg-576.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 4%;
    width: 70%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 35px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 15px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 4%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 15px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 15px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 15px;
  }

  .placeholder {
    font-size: 15px;
    color: #25272b;
  }
  .menu {
    font-size: 15px;
  }
}
@media (min-width: 768px) and (max-height: 1024px) {
  .top-part {
    background-image: url("../../assets/header-bg-768.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 300%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;

    resize: both;
  }
  .topHeadingPart {
    padding-top: 4%;
    width: 70%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 18px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 30px;
    height: 30px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 10%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 18px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 18px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
    font-size: 18px;
  }

  .dropdown {
    width: 75%;
    margin-top: 3px;
    height: 30px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 25px;
  }

  .placeholder {
    font-size: 18px;
    color: #25272b;
  }
  .menu {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (min-height: 1024.01px) {
  .top-part {
    background-image: url("../../assets/header-bg-768.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 4%;
    width: 70%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: column;
  }
  .top-text-div {
    
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 18px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 30px;
    height: 30px;
    color: #8a8280;
  }
  .filter-form {
    margin-top: 10%;
    background-color: #ffffff90;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 18px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 18px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
    font-size: 18px;
  }

  .dropdown {
    width: 75%;
    margin-top: 3px;
    height: 30px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 25px;
  }

  .placeholder {
    font-size: 18px;
    color: #25272b;
  }
  .menu {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-height:600px){
  .top-part {
    background-image: url("../../assets/header-bg-992.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200%;
    width: 100%;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 14%;
    width: 95%;
    margin-left: 4%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-text-div {
    width: 50%;
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    background-color: #ffffff90;
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 15px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 15px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 15px;
  }

  .placeholder {
    font-size: 15px;
    color: #25272b;
  }
  .menu {
    font-size: 15px;
  }
}
@media (min-width: 992px) and (min-height:601px) {
  .top-part {
    background-image: url("../../assets/header-bg-992.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 175%;
    width: 100%;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 5%;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-text-div {
    flex: 0.6;
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    background-color: #ffffff90;
    flex: 0.5;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 15px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 15px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 15px;
  }

  .placeholder {
    font-size: 15px;
    color: #25272b;
  }
  .menu {
    font-size: 15px;
  }
}
@media (min-width: 992px) and (min-height:1200px) {
  .top-part {
    background-image: url("../../assets/header-bg-992.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 105%;
    width: 100%;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 2%;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-text-div {
    flex: 0.6;
  }
  .headingDiv {
    width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 40px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 80%;
    padding-left: 5px;
    border-left: solid #0dc49a 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    background-color: #ffffff90;
    flex: 0.4;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 15px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 15px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 120px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 15px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 5px;
  }

  .Dropdown-control {
    width: 100%;
    height: 35px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 15px;
  }

  .placeholder {
    font-size: 15px;
    color: #25272b;
  }
  .menu {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .top-part {
    background-image: url("../../assets/header-bg-1200.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 150%;
    width: 100%;
    background-size: cover;
    margin-top: 5px;
    resize: both;
  }
  .topHeadingPart {
    padding-top: 2%;
    width: 94%;
    margin-left: 2%;
    margin-left: 2%;
  }
  .top-text-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-text-div {
    width: 50%;
  }
  .headingDiv {
    width: 60%;
  }
  h1 {
    color: #fff;
    font-size: 35px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 70%;
    padding-left: 4px;
    border-left: solid #0dc49a 6px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 15px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 8px;
  }
  .searchbar {
    margin-top: 2%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 10px;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: -3%;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 12px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 20px;
    height: 20px;
    color: #8a8280;
  }
  .filter-form {
    background-color: #ffffff90;
    width: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-form p {
    font-size: 11px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 12px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 12px;
  }
  .filter-form-center {
    margin-top: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
  }

  .dropdown {
    width: 75%;
    margin-top: 10px;
  }

  .Dropdown-control {
    width: 100%;
    height: 25px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 12px;
  }

  .placeholder {
    font-size: 12px;
    color: #25272b;
  }
  .menu {
    font-size: 12px;
  }
}
@media (min-width: 1400px) {
  .top-part {
    background-image: url("../../assets/header-bg-576.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 150%;
    width: 100%;
    margin-top: 5px;
    overflow-y: scroll;
    resize: both;
  }
  .topHeadingPart {
    width: 94%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .top-text-form-container {
    margin-top: -5%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .top-text-div {
    width: 60%;
  }
  .headingDiv {
    width: 70%;
  }
  h1 {
    color: #fff;
    font-size: 30px;
    letter-spacing: 2px;
  }
  .paraDiv {
    width: 70%;
    padding-left: 5px;
    border-left: solid #0dc49a 10px;
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    border-left: 10px red;
    font-size: 14px;
    text-align: left;
    font-weight: lighter;
    letter-spacing: 1px;
    padding-left: 5px;
  }
  .searchbar {
    margin-top: 4%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #searchbar {
    background-color: transparent;
    border: none;
    font-size: 14px;
    width: 95%;
    outline-width: 0;
  }
  #search-icon {
    width: 25px;
    height: 25px;
    color: #8a8280;
  }
  .filter-form {
    background-color: #ffffff90;
    width: 40%;
    padding-top: 5px;
    padding-bottom: 25px;
    border-radius: 10px;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
  }
  .filter-form p {
    font-size: 14px;
    color: #25272b;
  }
  #search-text {
    color: #25272b;
  }
  #select {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff90;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #category {
    background-color: transparent;
    border: none;
    font-size: 12px;
    width: 95%;
    outline-width: 0;
    color: #000000;
  }
  #select-button {
    border: none;
    display: flex;
    background-color: #25272b;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
    border-radius: 10px;
  }
  #select-button p {
    color: #ffffff;
    font-size: 10px;
  }
  .filter-form-center {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .filter-form-center p {
    color: #25272b;
    font-weight: 600;
    font-size: 12px;
  }
  #filter-form-id {
    height: 2px;
    width: 72%;
    background: #25272b;
    margin-right: 4%;
  }
  .drop-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin-top: 3%;
  }
  .drop-div p {
    color: #25272b;
    font-weight: 600;
    font-size: 12px;
  }

  .dropdown {
    width: 75%;
    margin-top: 3px;

  }

  .Dropdown-control {
    width: 100%;
    height: 40px;
    background-color: #ffffff90;
    background: #25272b;
    border-radius: 10px;
    font-size: 12px;
  }

  .placeholder {
    font-size: 12px;
    color: #25272b;
  }
  .menu {
    margin-top: 10%;
    font-size: 12px;
  }
}
