a {
  text-decoration: none;
}
@media (min-width: 0px) and (max-height: 740px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #marketica-logo {
    height: 20px;
    width: 20px;
  }
  #marketica-logo-text {
    font-size: 14px;
    font-weight: 500;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 10px;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .link-icon {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 8px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 3px;
    margin-right: 3px;
  }
  .small-icon {
    width: 6px;
    height: 8px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-right: 5%;
    padding-left: 5%;
  }
  .nav-button {
    color: #fff;
    font-size: 7px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 20%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 0px) and (min-height: 740.01px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
    display: flex;
    flex-direction: column;
  }
  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #marketica-logo {
    height: 20px;
    width: 20px;
  }
  #marketica-logo-text {
    font-size: 14px;
    font-weight: 500;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .link-icon {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 9px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 3px;
    margin-right: 3px;
  }
  .small-icon {
    width: 7.5px;
    height: 9.5px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-right: 5%;
    padding-left: 5%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 20%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 576px) and (max-height: 600px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 10%;
    margin-left: 10%;
  }
  #marketica-logo {
    height: 20px;
    width: 25px;
  }
  #marketica-logo-text {
    font-size: 14px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .link-icon {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 9px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    margin-left: 5px;
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .small-icon {
    width: 8px;
    height: 12px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 30%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 576px) and (min-height: 601.01px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 25%;
    margin-left: 10%;
  }
  #marketica-logo {
    height: 25px;
    width: 25px;
  }
  #marketica-logo-text {
    font-size: 16px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .link-icon {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 9px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .small-icon {
    width: 8px;
    height: 12px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 30%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 768px) and (max-height: 1024px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    margin-left: 2%;
  }
  #marketica-logo {
    height: 25px;
    width: 25px;
  }
  #marketica-logo-text {
    font-size: 18px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 10px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .small-icon {
    width: 10px;
    height: 12px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 40%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 768px) and (min-height: 1024.01px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    margin-left: 2%;
  }
  #marketica-logo {
    height: 25px;
    width: 25px;
  }
  #marketica-logo-text {
    font-size: 18px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 10px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .small-icon {
    width: 10px;
    height: 12px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 40%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 992px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    margin-left: 1%;
  }
  #marketica-logo {
    height: 35px;
    width: 35px;
  }
  #marketica-logo-text {
    font-size: 20px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 10px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .small-icon {
    width: 10px;
    height: 12px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 40%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 7px;
    font-weight: 600;
  }
}
@media (min-width: 1200px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    margin-left: 1%;
  }
  #marketica-logo {
    height: 35px;
    width: 35px;
  }
  #marketica-logo-text {
    font-size: 20px;
    font-weight: 600;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
  .link-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 15px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 6px;
    margin-right: 6px;
  }
  .small-icon {
    width: 12px;
    height: 18px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 40%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}
@media (min-width: 1400px) {
  .Footer {
    margin-top: 5%;
    padding-top: 5%;
    width: 100%;
    background-color: #25272b;
  }
  .footer-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    align-self: center;
    margin-bottom: 5%;
  }
  .logoSection-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
  #marketica-logo {
    height: 45px;
    width: 45px;
  }
  #marketica-logo-text {
    font-size: 25px;
    font-weight: bolder;
  }
  .contact-buttons {
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 75%;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
  .link-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  .link p {
    color: #fff;
    font-size: 15px;
    border-left: 1px solid #fff;
    padding-left: 2px;
    margin-left: 2px;
  }
  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .small-container {
    margin-left: 6px;
    margin-right: 6px;
  }
  .small-icon {
    width: 12px;
    height: 18px;
  }
  .footer-bottom {
    align-self: center;
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding-right: 5%;
    padding-left: 10%;
  }
  .nav-button {
    color: #fff;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }
  .line-border {
    background-color: #fff;
    width: 40%;
    height: 1px;
  }
  .bottom {
    width: 100%;
    background-color: #00c798;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom p {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}
