@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
* {
    font-family: 'Poppins';
}
html,body
{
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
}


