@media (min-width: 0px) and (max-height: 740px) {
  .form-container {
    margin-top: 3%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-text-container h1 {
    font-size: 14px;
    font-weight: 700;
    color:#25272B ;
    line-height: 2px;
  }
  .form-text-container h2 {
    font-size: 12px;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
  }
  .formContainer {
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 9px;
   font-weight: 600; 
  }
  .longInput {
    width: 100%;
    font-size: 7px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
  }
  .twoInputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .shortInput {
    width:150%;
    font-size: 7px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
}
  .short-input-placeholder {
    font-size: 8px;
    font-weight: 500;
    background-color: #FFFFFF;
  }
  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 7px;
    font-weight: 500;
    padding: 3px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 0px) and (min-height: 740.01px) {
  .form-container {
    margin-top: 3%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-text-container h1 {
    font-size: 14px;
    font-weight: 700;
    color:#25272B ;
    line-height: 2px;
  }
  .form-text-container h2 {
    font-size: 12px;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
  }
  .formContainer {
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 9px;
   font-weight: 600; 
  }
  .longInput {
    width: 100%;
    font-size: 7px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
  }
  .twoInputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .shortInput {
    width:150%;
    font-size: 7px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
}
  .short-input-placeholder {
    font-size: 8px;
    font-weight: 500;
    background-color: #FFFFFF;
  }
  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 7px;
    font-weight: 500;
    padding: 3px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-height: 600px) {
  .form-container {
    margin-top: 3%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 18px;
    font-weight: 700;
    color:#25272B ;
    line-height: 3px;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 14x;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
    width: 90%;
    text-align: center;
  }
  .formContainer {
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
    border-radius: 4px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 14px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 60%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 30px;
    border-radius: 5px;
  }
  .twoInputs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   width: 80%;
  }
  .shortInput {
    margin-top: 5px;
    width:50%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 20%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 500;
  }
}
@media (min-width: 576px) and (min-height: 601.01px) {
  .form-container {
    margin-top: 3%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 18px;
    font-weight: 700;
    color:#25272B ;
    line-height: 3px;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 14x;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
    width: 90%;
    text-align: center;
  }
  .formContainer {
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
    border-radius: 4px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 14px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 60%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 30px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 5px;
    width:50%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 20%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 500;
  }
}
@media (min-width: 768px) and (max-height: 1024px) {
  .form-container {
    margin-top: 4%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 25px;
    font-weight: 600;
    color:#25272B ;
    line-height: 12px;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 18x;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
    width: 90%;
    text-align: center;
  }
  .formContainer {
    margin-top: 4%;
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
    border-radius: 6px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 14px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 30px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 5px;
    width:45%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 16%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 500;
  }
}
@media (min-width: 768px) and (min-height: 1024.01px) {
  .form-container {
    margin-top: 4%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 25px;
    font-weight: 600;
    color:#25272B ;
    line-height: 12px;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 18x;
    font-weight: 600;
    color: #00C798;
    line-height: 12px;
    width: 90%;
    text-align: center;
  }
  .formContainer {
    margin-top: 4%;
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
    border-radius: 6px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 14px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 30px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 5px;
    width:45%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 14px;
    font-weight: 500;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 15%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 800;
  }
}
@media (min-width: 992px) {
  .form-container {
    margin-top: 4%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: larger;
    font-weight: 600;
    color:#25272B ;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: large;
    font-weight: 600;
    color: #00C798;
    text-align: center;
  }
  .formContainer {
    margin-top: 4%;
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 90%;
    resize: both;
    background-size: cover;
    border-radius: 6px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .label {
   font-size: 14px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 30px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 5px;
    width:45%;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 14px;
    font-weight: 600;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 15%;
    padding-top: 10px;
    padding-bottom:10px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .form-container {
    margin-top: 2%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 20px;
    font-weight: 800;
    color:#25272B ;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 17px;
    font-weight: 600;
    color: #00C798;
    text-align: center;
  }
  .formContainer {
    margin-top: 1%;
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    resize: both;
    background-size: cover;
    border-radius: 6px;
  }
  .form {
    padding-left: 4%;
    padding-top: 0.1%;
    padding-bottom: 0.5%;
    width: 80%;
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2%;
  }
  .label {
   font-size: 12px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 5px;
    width: 50%;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 25px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 3px;
    width:45%;
    font-size: 10px;
    font-weight: 600;
    outline: none;
    background-color: #FFFFFF;
    border: 0.2px solid #000000;
    height: fit-content;
    border-radius: 4px;
  }
  .short-input-placeholder {
    font-size: 9px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 25px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 9px;
    font-weight: 600;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 9px;
    width: 10%;
    padding-top: 7px;
    padding-bottom:7px;
    font-weight: 500;
    justify-content: center;
    align-items: center;

  }
}
@media (min-width: 1400px) {
  .form-container {
    margin-top: 4%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-text-container h1 {
    font-size: 20px;
    font-weight: 800;
    color:#25272B ;
    text-align: center;
  }
  .form-text-container h2 {
    font-size: 15px;
    font-weight: 600;
    color: #00C798;
    text-align: center;
  }
  .formContainer {
    margin-top: 1%;
    background-image: url("../../assets/Form-BG.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    resize: both;
    background-size: cover;
    border-radius: 6px;
  }
  .form {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
  .input {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5%;
  }
  .label {
   font-size: 10px;
   font-weight: 600;
   width: 100%; 
  }
  .form input {
    margin-top: 3px;
    width: 40%;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    height: 25px;
    border-radius: 5px;
  }
  .twoInputs {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .shortInput {
    margin-top: 5px;
    width:45%;
    font-size: 10px;
    font-weight: 500;
    outline: none;
    background-color: #FFFFFF;
    border: 0.5px solid #000000;
    height: fit-content;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
  .short-input-placeholder {
    font-size: 10px;
    font-weight: 600;
    background-color: #FFFFFF;
    height: 25px;
    justify-content: center;
    align-items: center;
  }
  .short-menu {
    font-size: 10px;
    font-weight: 500;
    background-color: #FFFFFF;
  }

  #submit-button {
    color: #FFFFFF;
    background-color: #25272B;
    border: none;
    border-radius: 4px;
    font-size: 10px;
    width: 10%;
    padding-top: 8px;
    padding-bottom:8px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-weight: 300;
  }
  
}
