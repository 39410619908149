#side-arrow:hover {
  background-color: #d7d7d750;
}

@media (min-width: 0px) and (max-height: 740px) {
  .OrgList {
    margin-top: -10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .side p {
    font-size: 7px;
    color: #25272b;
    text-align: center;
    font-weight: 500;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 50%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 2px;
    margin-bottom: -6px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 60%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 2px;
    margin-bottom: -6px;
  }
  #arrow {
    height: 6px;
    width: 9px;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }
  .organization-container {
    width: 85%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 80px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 8px;
  }
  #org-name {
    font-size: 10px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 7px;
    color: #50CFFB;
    font-weight: 400;
  }
  #mission-text {
    color: #25272b;
    font-size: 8px;
  }
  .organization-container a:hover {
    color: red;
  }

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: #d7d7d750;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: #d7d7d7;
  }
  #bottom-arrow {
    width: 50%;
    height: 3.5%;
  }
  .org-bottom-button {
    margin-top: 2%;
    width: 30%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    background-color: #00c798;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 12px;
    font-weight: 600;
  }
}
@media (min-width: 0px) and (min-height: 740.01px) {
  .OrgList {
    margin-top: -15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  .side p {
    font-size: 10px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 60%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 2px;
    padding-top: 4%;
    padding-bottom: 1%;
    margin-bottom: -10px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 60%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 2px;
    padding-top: 4%;
    padding-bottom: 1%;
    margin-bottom: -10px;
  }
  #arrow {
    height: 2%;
    width: 50%;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }

  .organization-container {
    width: 85%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 80px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 8px;
  }
  #org-name {
    font-size: 10px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 7px;
    color: #50CFFB;
    font-weight: 400;
  }
  #mission-text {
    color: #25272b;
    font-size: 8px;
  }
  .organization-container a:hover {
    color: red;
  }


  .org-bottom{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
  #bottom-arrow {
    width: 60%;
    height: 4%;
  }
  .org-bottom-button {
    margin-top: 2%;
    width: 25%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 12px;
    font-weight: 600;
  }
}
@media (min-width: 576px) and (max-height: 600px) {
  .OrgList {
    margin-top: 1%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .side p {
    font-size: 14px;
    color: #25272b;
    text-align: center;
    font-weight: 700;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    margin-bottom: -5%;
    padding-top: 4%;
    padding-bottom: 1%;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    margin-bottom: -5%;
    padding-top: 4%;
    padding-bottom: 1%;
  }
  #arrow {
    height: 3.5%;
    width: 50%;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }

  .organization-container {
    width: 85%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 80px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 8px;
  }
  #org-name {
    font-size: 10px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 8px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 9px;
  }
  .organization-container a:hover {
    color: red;
  }


  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    justify-content: center;
    align-items: center;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    justify-content: center;
    align-items: center;
  }
  #bottom-arrow {
    width: 70%;
    height: 4.5%;
  }
  .org-bottom-button {
    margin-top: 2.5%;
    width: 15%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 13px;
    font-weight: 600;
  }
}
@media (min-width: 576px) and (min-height: 601.01px) {
  .OrgList {
    margin-top: 1%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .side p {
    font-size: 15px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .bottom-arrow-container {
    background-color: #d7d7d750;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    border-radius: 5px;
    margin-bottom: -10px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    border-radius: 5px;
    margin-bottom: -10px;
  }
  #arrow {
    height: 4%;
    width: 50%;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }
  
  .organization-container {
    width: 85%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 80px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 8px;
  }
  #org-name {
    font-size: 10px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 8px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 9px;
  }
  .organization-container a:hover {
    color: red;
  }

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    border-radius: 6px;
    width: 10%;
    margin-top: 1.5%;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    border-radius: 6px;
    width: 10%;
  }
  #bottom-arrow {
    width: 20px;
    height: 12px;
  }
  .org-bottom-button {
    margin-top: 2.5%;
    width: 20%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 15px;
    font-weight: 600;
  }
}
@media (min-width: 768px) and (max-height: 1024px) {
  .OrgList {
    margin-top: -10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .side p {
    font-size: 18px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 40%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1.5%;
    margin-bottom: -5px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 2px;
    padding-bottom: 2px;
    border: none;
    width: 40%;
    margin-left: 8%;
    margin-right: 8%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1.5%;
    margin-bottom: -5px;
  }
  #arrow {
    height: 4%;
    width: 50%;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }
  
  .organization-container {
    width: 85%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 120px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 10px;
  }
  #org-name {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 10px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 11px;
  }
 

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #bottom-arrow {
    width: 90%;
    height: 5.5%;
  }
  .org-bottom-button {
    margin-top: 2.5%;
    width: 16%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 15px;
    font-weight: 600;
  }
}
@media (min-width: 768px) and (min-height: 1024.01px) {
  .OrgList {
    margin-top: -12%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .side p {
    font-size: 18px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 1%;
    padding-bottom: 1%;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 9%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    margin-bottom: -8px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 1%;
    padding-bottom: 1%;
    border: none;
    width: 50%;
    margin-left: 8%;
    margin-right: 9%;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    margin-bottom: -8px;
  }
  #arrow {
    height: 20px;
    width: 30px;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .orgrow {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    width: 50%;
  }
  
    
  .organization-container {
    width: 85%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 150px;
    border: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 10px;
  }
  #org-name {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 10px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 11px;
  }
 
  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 2%;
    padding: 1.5%;
    border-radius: 4px;
    margin-top: 1%;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 2%;
    padding: 1.5%;
    border-radius: 4px;
  }
  #bottom-arrow {
    width: 90%;
    height: 12.5%;
  }
  .org-bottom-button {
    margin-top: 2.5%;
    width: 16%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 16px;
    font-weight: 600;
  }
}
@media (min-width: 992px) {
  .OrgList {
    margin-top: -40%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .side p {
    font-size: 20px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 1.5%;
    padding-bottom: 1%;
    border: none;
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    border-radius: 8px;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 1.5%;
    padding-bottom: 1%;
    border: none;
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 1%;
    border-radius: 8px;
  }
  #arrow {
    height: 2%;
    width: 50%;
    resize: both;
    overflow: visible;
  }
  .center {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .orgrow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1%;
  }

  .organization-container {
    width: 85%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 140px;
    border: none;
    border-radius: 10px;
    outline: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:80px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 10px;
  }
  #org-name {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 10px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 11px;
  }

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 2%;
    padding: 1%;
    border-radius: 8px;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6%;
    height: 2%;
    padding: 1%;
    border-radius: 8px;
  }
  #bottom-arrow {
    width: 90%;
    height: 12.5%;
  }
  .org-bottom-button {
    margin-top: 1%;
    width: 16%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 16px;
    font-weight: 600;
  }
}
@media (min-width: 1200px) {
  .OrgList {
    margin-top: -40%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.15;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .side p {
    font-size: 14px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }

  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 6%;
    padding-bottom: 1.5%;
    border: none;
    width: 35%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    margin-bottom: -8px;
  }

  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 6%;
    padding-bottom: 1.5%;
    border: none;
    width: 35%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    margin-bottom: -8px;
  }
  #arrow {
    height: 2%;
    width: 50%;
    resize: both;
    overflow: visible;
  }
  .center {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .orgrow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5%;
  }
  
  .organization-container {
    width: 35%;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 180px;
    border: none;
    border-radius: 10px;
    outline: none;
  }
  #website-link-id {
    position:absolute; top:0; left:0; display:inline-block; width:90%; height:180px; z-index:5;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 10px;
  }
  #org-name {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 10px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 11px;
  }

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4%;
    height: 2%;
    padding: 1%;
    border-radius: 8px;
    margin-top: 0.7%;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4%;
    height: 2%;
    padding: 1%;
    border-radius: 8px;
  }
  #bottom-arrow {
    width: 50%;
    height: 8px;
  }
  .org-bottom-button {
    margin-top: 0.4%;
    width: 10%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 14px;
    font-weight: 600;
  }
}
@media (min-width: 1400px) {
  .OrgList {
    margin-top: -40%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .side {
    flex: 0.15;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .side p {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: 600;
  }
  .grey-arrow-container {
    background-color: #d7d7d750;
    padding-top: 1.5%;
    padding-bottom: 1%;
    border: none;
    width: 20%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
  .arrow-container {
    background-color: #d7d7d7;
    padding-top: 1.5%;
    padding-bottom: 1%;
    border: none;
    width: 20%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
  #arrow {
    height: 1%;
    width: 40%;
    resize: both;
    overflow: visible;
  }
  .center {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .orgrow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5%;
  }


  .organization-container {
    width: 35%;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
  .orgnaization-item {
    width: 90%;
    height: 180px;
    border: none;
    border-radius: 10px;
    outline: none;
    pointer-events: none;
  }
  #country-name {
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    color: #05C49A;
    font-size: 10px;
  }
  #org-name {
    font-size: 12px;
    color: #25272b;
    text-align: center;
    font-weight: bold;
  }
  .organization-container a {
    text-decoration: none;
    font-size: 10px;
    color: #50CFFB;
    font-weight: 400;
    margin-top: 2px;
  }
  #mission-text {
    margin-top: 2px;
    color: #25272b;
    font-size: 11px;
  }

  .org-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }
  .bottomArrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1%;
  }
  .grey-bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d750;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 8px;
    margin-bottom: 0.2%;
  }
  .bottom-arrow-container {
    margin-right: 2.5px;
    margin-left: 2.5px;
    background-color: #d7d7d7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 8px;
  }
  #bottom-arrow {
    width: 60%;
    height: 8px;

  }
  .org-bottom-button {
    margin-top: 0.4%;
    width: 8%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #00c798;
    border-radius: 5px;
  }
  .org-bottom-button p {
    color: #25272b;
    font-size: 15px;
    font-weight: 600;
  }
}
